
import {
  send as logSend
} from 'smith-log'
export default {
  name: 'index',
  mounted() {
    if (this.$route.query.message) {
      this.$message.error({ message: this.$route.query.message, duration: 5000 })
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('message');
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.pushState({}, '', newUrl);
    }
    logSend('MV', 'e_ca_website_search', {
      source: this.$route.query?.source,
      page_id: 'p_ca_website_search'
    })
  }
};
