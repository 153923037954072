import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0f0b9424&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f0b9424",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchWrapper: require('/usr/local/hirey/prod/server/pc/components/SearchWrapper.vue').default,SearchResult: require('/usr/local/hirey/prod/server/pc/components/SearchResult.vue').default,Wrapper: require('/usr/local/hirey/prod/server/pc/components/Wrapper.vue').default})
